<template>
  <div class="d-flex justify-content-between align-item-center">
    <div class="position-relative bg-dark h-100 w-100 custom-padding">
      <div
        class="rounded video-frame d-flex justify-content-center align-items-center shadow-md bg-img"
        :style="`background-image: ${stream.id && 'none !important'}`"
      >
        <div class="position-absolute bottom-0">
          <div
            class="p-3 text-white bg-semitrans-dark rounded"
            v-if="!stream.name"
          >
            Click thumbnail below to start stream
          </div>
          <button
            class="btn btn-secondary btn-sm text-decoration-none position-absolute"
            v-if="stream.isOnline"
            style="bottom: 1rem; right: 1rem"
            @click="streamFullscreen"
          >
            <i class="fas fa-expand mr-2" />
            Fullscreen
          </button>
        </div>
        <video
          id="liveStream"
          muted
          playsinline
          class="videoPlayer"
          :class="{ invisible: !stream.isOnline }"
        >
          <source id="mp4" type="video/mp4" />
        </video>
        <div
          class="position-absolute"
          v-if="stream.isInitialized && isLoadingStream"
        >
          <div class="spinner-border"></div>
          <p class="mb-0 mt-2 text-light">Loading...</p>
        </div>
        <div
          class="position-absolute rounded py-2 px-3"
          v-else-if="stream.isInitialized && !stream.isOnline"
        >
          <p class="mb-0 h4 text-white">
            {{ stream.name }} - Stream is Offline
          </p>
        </div>
      </div>
      <div
        class="video-frame-button mt-3 rounded d-flex flex-wrap is-cursor row mx-0"
      >
        <div
          v-for="live in lives"
          :key="live._id"
          class="video-thumbnail mr-2 rounded shadow overflow-hidden col"
          :style="`background-color: ${
            stream.id == live.id ? '#28343E' : ''
          } !important; border: ${
            stream.id == live.id ? '2px solid #303F4B ' : ''
          }`"
        >
          <div
            class="position-absolute pt-2 pl-2 text-light border-bottom border-dark pb-2 w-100"
            :style="`top: 0;`"
          >
            {{ live.name }}
          </div>
          <div class="d-flex justify-content-center align-items-center h-100">
            <button
              v-if="stream.id != live.id"
              @click="initializeLiveStream(live)"
              type="button"
              class="btn btn-dark shadow"
              style="opacity: 0.8"
            >
              Click to view live stream
            </button>
            <div
              v-else
              class="bg-white rounded shadow px-3 py-2"
              style="opacity: 0.8"
              :class="{
                invisible:
                  stream.isInitialized && !stream.isOnline ? 'none' : 'visible',
              }"
            >
              <p v-if="stream.isInitialized && !stream.isOnline" class="m-0">
                Stream is Offline
              </p>
              <p v-else class="m-0 blinking">
                <i class="fas fa-broadcast-tower mr-2" />Live
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import flvjs from "flv.js";

export default {
  data() {
    return {
      isLoading: false,
      stream: {
        // url: "https://playback.inference.asia/live/selgem.flv",
        url: null,
        isOnline: false,
        isInitialized: false,
      },
      flvPlayer: null,
      isLoadingStream: false,
      lives: [],
    };
  },
  methods: {
    async initializeLiveStream(live) {
      this.stream = {
        ...live,
        isInitialized: true,
      };
      this.isLoadingStream = true;

      await this.loadStream();

      this.isLoadingStream = false;
    },
    streamFullscreen() {
      var elem = document.getElementById("liveStream");

      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.webkitRequestFullscreen) {
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) {
        elem.msRequestFullscreen();
      }
    },
    async loadStream() {
      if (this.stream.url) {
        if (flvjs.isSupported()) {
          let videoElement = document.getElementById("liveStream");

          flvjs.LoggingControl.removeLogListener(this.flvjsLogListener);

          flvjs.LoggingControl.addLogListener(this.flvjsLogListener);

          if (typeof this.flvPlayer !== "undefined") {
            if (this.flvPlayer != null) {
              this.flvPlayer.unload();
              this.flvPlayer.detachMediaElement();
              this.flvPlayer.destroy();
              this.flvPlayer = null;
            }
          }

          let mp4 = document.getElementById("mp4");

          videoElement.controls = false;

          mp4.src = null;

          this.flvPlayer = flvjs.createPlayer({
            type: "flv",
            url: this.stream.url,
            hasAudio: false,
            enableStashBuffer: false,
          });

          this.flvPlayer.attachMediaElement(videoElement);

          this.flvPlayer.load();

          return;
        }
      }
    },
    flvjsLogListener(_, str) {
      if (str.includes("onSourceEnded")) {
        this.stream.isOnline = false;
      }

      if (str.includes("Received Initialization")) {
        if (typeof this.flvPlayer !== "undefined" && this.flvPlayer != null) {
          this.stream.isOnline = true;

          this.flvPlayer.play();
        }
      }
    },
  },
  async mounted() {
    const [call, err] = await this.Helper.handle(this.API.get("lives"));
    this.lives = call.data.map((x) => {
      let streamKey = x.streamUrl.split("/");
      return {
        id: x.id,
        name: x.name,
        url: `https://playback.inference.asia/live/${streamKey[4]}.flv`,
        isOnline: false,
        isInitialized: false,
      };
    });
  },
};
</script>

<style scoped>
.custom-padding {
  padding: 6rem 1.5rem 2rem 1.5rem !important;
}

.video-frame {
  position: relative;
  height: 60vh;
  background-color: #1b252b;
}

.bg-img {
  background-image: url("../assets/images/infrasel-logo-white.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.bg-no-img {
  background-image: none !important;
}

.video-frame-button {
  position: relative;
  height: 20vh;
}

.video-thumbnail {
  position: relative;
  display: block;
  height: 100%;
  width: 300px;
  background-image: url("../assets/images/infrasel-logo-white.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  background-color: #1b252b;
}

.video-thumbnail:hover {
  opacity: 0.7;
}

.is-cursor {
  cursor: pointer !important;
}
</style>